import Accordions from '@blocks/accordions'
import React, { useState, useEffect, useCallback } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'
import axios from 'axios'
import useSWR from 'swr'
import Link from 'next/link'
import Photo from '@components/photo'

import {
  ProductGallery,
  ProductPrice,
  ProductForm,
  ProductActions,
} from '@blocks/product'
import { useI18nContext } from '@lib/i18n'
import Icon from '@components/icon'
import { canonicalUrl } from '@components/link'

// setup our inventory fetcher
const fetchInventory = (url, id) => {
  return axios
    .get(url, {
      params: {
        id: id,
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((e) => console.log('error', e))
}

const ProductHero = ({
  product: initialProduct,
  activeVariantId,
  onVariantChange: customOnVariantChangeId,
  showSummary,
}) => {
  const [product, setProduct] = useState(initialProduct)
  // set active variant as default
  const [localActiveVariantId, setActiveVariantId] = useState(
    activeVariantId ? activeVariantId : product.variants[0].id
  )

  const onVariantChange = (id) => {
    const newActiveVariant = product.variants.find((v) => v.id === id)
    customOnVariantChangeId
      ? customOnVariantChangeId(newActiveVariant.id)
      : setActiveVariantId(newActiveVariant.id)
  }

  // Check our product inventory is still correct
  const { data: productInventory } = useSWR(
    ['/api/shopify/product-inventory', product.id],
    (url, id) => fetchInventory(url, id),
    { errorRetryCount: 3 }
  )

  useEffect(() => {
    if (product && productInventory) {
      setProduct({
        ...product,
        inStock: productInventory.inStock,
        lowStock: productInventory.lowStock,
        variants: [
          ...product.variants.map((v) => {
            const newInventory = productInventory.variants.find(
              (nv) => nv.id === v.id
            )
            return newInventory ? { ...v, ...newInventory } : v
          }),
        ],
      })
    }
  }, [initialProduct, productInventory])

  const activeVariant = product.variants.find(
    (v) => v.id == (activeVariantId ?? localActiveVariantId)
  )
  const { t } = useI18nContext()

  const productTitle = (
    <>
      {activeVariant && (
        <div className="product--variant">
          {activeVariant.title !== 'Default Title' && activeVariant.title}

          {activeVariant.lowStock && activeVariant.inStock && (
            <span className="label is-secondary">
              {t(`Limited stock`, `低在庫`)}
            </span>
          )}

          {!activeVariant.inStock && (
            <span className="label is-secondary">
              {t(`Out of stock`, `在庫切れ`)}
            </span>
          )}
        </div>
      )}
      <h1 className="product--name">{product.title}</h1>
    </>
  )

  const description = (
    <BlockContent
      renderContainerOnSingleChild
      className="rc"
      blocks={
        showSummary && product.shortDescription
          ? product.shortDescription
          : product.description
      }
      serializers={serializers}
    />
  )

  const materials = !showSummary && product.materials && (
    <>
      <h5 className="product--specs-heading">{t(`Materials`, `素材`)}</h5>

      <BlockContent
        renderContainerOnSingleChild
        className="rc product--specs-description"
        blocks={product.materials}
        serializers={serializers}
      />
    </>
  )

  const dimensions = !showSummary && product.dimensions && (
    <>
      <h5 className="product--specs-heading">{t('Dimensions', 'サイズ')}</h5>
      <BlockContent
        renderContainerOnSingleChild
        className="rc product--specs-description"
        blocks={product.dimensions}
        serializers={serializers}
      />
    </>
  )
  const manufacturedIn = !showSummary && product.manufacturedIn && (
    <>
      <h5 className="product--specs-heading">
        {t('Manufacture location', '製造地')}
      </h5>
      <p className="product--specs-description">{product.manufacturedIn}</p>
    </>
  )
  const productUrl = canonicalUrl(
    `/products/${
      product.slug +
      (product.surfaceOption ? `?variant=${activeVariant.id}` : '')
    }`
  )
  return (
    <section className="product">
      <div className="product--content">
        <div className="product--gallery">
          {showSummary ? (
            <Link href={productUrl} scroll={false}>
              <a>
                {
                  <ProductGallery
                    hasDrag={false}
                    photosets={product.photos.main}
                    activeVariant={activeVariant}
                  />
                }
              </a>
            </Link>
          ) : (
            <ProductGallery
              photosets={product.photos.main}
              activeVariant={activeVariant}
              hasArrows
              hasCounter
              hasThumbs
              // inlinePhotos
            />
          )}
        </div>

        <div className="product--details">
          <div className="product--info">
            <div className="product--header">
              <div className="product--title">
                {showSummary ? (
                  <Link href={productUrl} scroll={false}>
                    <a>{productTitle}</a>
                  </Link>
                ) : (
                  productTitle
                )}
              </div>
            </div>
            <div className="product--desc">
              {showSummary ? (
                <Link href={productUrl} scroll={false}>
                  <a>{description}</a>
                </Link>
              ) : (
                <>{description}</>
              )}
              {materials}
              {dimensions}
              {manufacturedIn}

              {!showSummary ||
              (product.globalProductInfo && product.globalProductInfo.length) ||
              (product.sharedProductInfo &&
                product.sharedProductInfo.length) ? (
                <div className="product--shared-info">
                  {product.sharedProductInfo && (
                    <Accordions data={{ items: product.sharedProductInfo }} />
                  )}
                  {product.globalProductInfo && (
                    <Accordions data={{ items: product.globalProductInfo }} />
                  )}
                </div>
              ) : null}

              {showSummary && (
                <Link href={productUrl} scroll={false}>
                  <a className="product--learn-more">
                    <span>{t(`Learn more`, `さらに詳しく`)}</span>{' '}
                    <Icon viewBox="0 0 24 24" name="ChevronRight" />
                  </a>
                </Link>
              )}
              <div className="mt-8">
                <span className="text-xs">
                  {activeVariant.title !== 'Default Title' &&
                    activeVariant.title}
                </span>
                <ProductPrice
                  price={activeVariant?.price || product.price}
                  comparePrice={
                    activeVariant?.comparePrice || product.comparePrice
                  }
                />
              </div>
              <ProductForm
                product={product}
                activeVariant={activeVariant}
                onVariantChange={onVariantChange}
                className="product--form"
              />
              <ProductActions
                showLearnMore={false}
                slug={product.slug}
                surfaceOption={product.surfaceOption}
                activeVariant={activeVariant}
                klaviyoAccountID={product.klaviyoAccountID}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductHero
